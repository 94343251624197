import React from 'react';

import { Wrapper, Inner } from './Spinner.styles';

export interface SpinnerProps {
  size?: 'S' | 'M' | 'L';
  color?: string;
  inline?: boolean;
}

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  SpinnerProps;

const Spinner = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    color,
    size = 'S',
    inline,
    ...rest
  } = props;

  return (
    <Wrapper
      {...rest}
      ref={ref}
      inline={inline}
    >
      <Inner
        color={color}
        size={size}
      />
    </Wrapper>
  );
});

export default Spinner;
