import i18next from 'i18next';
import {
  formatDuration,
  intervalToDuration,
  format
} from 'date-fns';

import { Step, Field, FieldType } from 'types/Service'
import { DropdownOption, BrowserWindow } from 'types/UI';
import {
  Status,
  StatusType,
  ChangeType
} from 'types/Job';
import {
  Client,
  CustomerPaymentServiceType,
  AccountingPlatform
} from 'types/Client';
import { Customer } from 'types/Customer';
import { NetworkError, LocalError } from 'types/Error';
import { PaymentOption } from 'types/Enums';
import { User } from 'types/User';
import { Job } from 'types/Job';
import { Fulfiller } from 'types/Fulfiller';
import { sizes } from 'theme/media';

export const doubleSingleDigit = (segment: string | number): string => {
  const str = String(segment);

  return str.length === 1 ? `0${str}` : str;
};

export const removeTimeInISOString = (dateTimeISOString: string): string => {
  return `${dateTimeISOString.split('T')[0]}T00:00:00.000Z`;
};

export const isNumeric = (e: any) => {
  e = e || window.event;

  let key = e.keyCode || e.which;
  key = String.fromCharCode(key);
  const regex = /[0-9]|\./;
  if ( !regex.test(key) ) {
    e.returnValue = false;

    if (e.preventDefault) {
      e.preventDefault();
    }
  }
};

export const formatCurrency = (value: number): string => {
  value = value || 0;

  return new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: 'GBP'
  })
  .format(value / 100);
};

// TODO: use Number() here instead
export const getRawCurrencyValue = (value: string): string => {
  return value.replace(/£|€|￥|\.|,/g, '');
};

export const animateValue = (start: number, end: number, duration: number, cb: (value: number) => void) => {
  let startTimestamp: any = null;

  const step = (timestamp: any) => {
    if (!startTimestamp) {
      startTimestamp = timestamp;
    }

    const progress = Math.min((timestamp - startTimestamp) / duration, 1);

    if (cb) {
      cb(progress * (end - start) + start);
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};

export const createDropdownOption = (lbl: string, val?: any): DropdownOption => ({
  label: lbl,
  value: val || lbl
});

export const formatJobStatus = (status: string): string => {
  let txt = status.toLowerCase();

  txt = `${txt.charAt(0).toUpperCase()}${txt.slice(1)}`.replace(/_/g, ' ');

  if (status.includes('CHARGES')) {
    txt = txt.replace('Charges', 'Chgs.');
  }

  return txt;
};

export const getStatusState = (status: StatusType): number => {
  let stateNumber: number = 0;

  switch(status) {
    case StatusType.SUBMITTED:
    case StatusType.CANCELLED:
    case StatusType.DEFERRED:
    case StatusType.DELAYED:
    case StatusType.CHARGES_PENDING:
      stateNumber = 0;
      break;
    case StatusType.PAID:
    case StatusType.PENDING:
      stateNumber = 1;
      break;
    case StatusType.COMPLETE:
    case StatusType.CHARGES_PAID:
      stateNumber = 2;
      break;
    case StatusType.DISPUTE:
    case StatusType.REFUNDED:
      stateNumber = 3;
      break;
  }

  return stateNumber;
};

export const getPaymentService = (serviceType: CustomerPaymentServiceType | null): string => {
  let type: string = '';

  if (!serviceType) {
    return type;
  }

  switch(serviceType) {
    case CustomerPaymentServiceType.Stripe:
      type = 'Stripe';
      break;
  }

  return type;
};

export const formatLabel = (status: string): string => {
  const txt = status
    .toLowerCase()
    .replace(/-/g, ' ');

  return `${txt.charAt(0).toUpperCase()}${txt.slice(1)}`;
};

// Note: clipboard will be null if in non HTTPS/localhost envs
export const copyText = (str: string): Promise<void | null> => {
  try {
    return navigator.clipboard.writeText(str);
  } catch (e) {
    return Promise.reject(null);
  }
};

export const getPaymentLink = (
  clientId: string,
  serviceId: string,
  index: number,
  jobId: string
): string => {
  return `${window.location.origin}/${clientId}/booking/${serviceId}/${index}/?job=${jobId}`;
};

export const getServiceLink = (
  clientId: string,
  serviceId: string
): string => {
  return `${window.location.origin}/${clientId}/booking/${serviceId}/1`;
};

export const isInBookingPage = (window: BrowserWindow): boolean => {
  return window.location.href.includes('/booking/');
};

export const isInBackofficePage = (window: BrowserWindow): boolean => {
  return window.location.href.includes('/back-office/');
};

export const generateRandomNumber = (min: number, max: number) => {
  return Math.ceil(Math.random() * (max - min)) + min;
}

export const serializeFilter = (filters: any[]) => {
  let str = '';

  filters.forEach((filter: any, index: number) => {
    str += `${Object.keys(filter)[0]}:${Object.values(filter)[0]}${index < filters.length - 1 ? '|' : ''}`;
  });

  str = encodeURIComponent(str);

  return str;
}

export const hasValue = (value: any): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  if (value.length || value.length === 0) {
    // string
    if (value.trim) {
      return value.trim().length > 0;
    }

    // array
    return value.length > 0;
  }

  // number
  if (!isNaN(value)) {
    return value >= 0;
  }

  if (value) {
    return true;
  }

  return false;
};

export const scrollToContent = (selector?: string, tryCount?: number, highlight?: boolean) => {
  const TRIES = 5;
  const TRY_DELAY = 1000;

  selector = selector || document.location.hash;

  if (selector.length === 0) {
    return;
  }

  const elem: HTMLElement | null = document.querySelector(selector);

  if (!elem) {
    if (!tryCount) {
      const initialTryCount = 1;

      setTimeout(() => {
        scrollToContent(selector, initialTryCount, highlight);
      }, TRY_DELAY);

      return;
    }

    if (tryCount && tryCount > TRIES) {
      return;
    }

    tryCount++;

    setTimeout(() => {
      scrollToContent(selector, tryCount, highlight);
    }, TRY_DELAY);

    return;
  }

  elem.scrollIntoView({
    behavior: 'smooth'
  });

  if (highlight) {
    if (!elem.classList.contains('highlight-element')) {
      elem.classList.add('highlight-element');

      setTimeout(() => {
        if (elem) {
          elem.classList.remove('highlight-element');
        }
      }, 5000);
    }
  }
};

export const isInIframe = (win: BrowserWindow) => {
  // return window.top !== win.self && win.isChild;
  return window.top !== win.self;
};

export const isFieldCalculable = (field: Field): boolean => {
  return field.type === FieldType.Number || field.type === FieldType.Dropdown
};

export const isDeepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) { // it's just the same object. No need to compare.
    return true;
  }

  if (isPrimitive(obj1) && isPrimitive(obj2)) {// compare primitives
    return obj1 === obj2;
  }

  // null / undefined cannot be passed to Object.keys so must do early check
  if ((obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) && obj1 !== obj2) {
    return false;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  // compare objects with same number of keys
  for (let key in obj1) {
    if (!(key in obj2)) { //other object doesn't have this prop
      return false;
    }

    if (!isDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

//check if value is primitive
export const isPrimitive = (obj: any) => {
  return (obj !== Object(obj));
}

export const objectIsEmpty = (obj: any): boolean => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

export const getNetworkErrors = (errors: any[]): NetworkError[] => {
  return errors
    .filter(e => !!e)
    .map((error: any) => {
      if (error instanceof NetworkError || error instanceof LocalError) {
        return error;
      }

      let networkError: NetworkError = new NetworkError();

      if (error.response) {
        const networkErrorCode: string | null = error.response.data.errorCode;

        networkError = new NetworkError(
          error.response.data.message || error.response.data.Message,
          error.response.status,
          !!networkErrorCode ? {
            errorCode: error.response.data.errorCode
          } : undefined
        );
      }
      else if (error.request) {
        console.log('1', error);

        // networkError = new NetworkError('Please ensure you are connected to the internet');
      } else {
        console.log('2', error.message, error);
      }

      return networkError;
    });
};

export const getInactiveUserIds = (users: User[]): string[] => {
  return users
    .filter((u: User | Fulfiller) => {
      return (u.deactivation && u.deactivation.deactivate)
        || u.revoke
    })
    .map((u: User | Fulfiller) => u._id);
};

export const isMinWidth = (width: number): boolean => {
  return document.documentElement.offsetWidth >= width;
};

export const getScrollParent = (node: HTMLElement | null): HTMLElement | null => {
  if (node === null) {
    return null;
  }

  // if (node.scrollHeight > node.clientHeight) {
  if (node.scrollHeight > node.offsetHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}

export const isListEmpty = (list: any[] | null): boolean => {
  if (!list) {
    return true;
  }

  return list.length === 0;
};

export const getFieldName = (name: string): string => {
  return name.toLowerCase().replace(/ /g, '-');
};

export const getFlattenFormFields = (groupedForm: any, updatedKeyValue?: any) => {
  let mergedObj: any = Object
    .values(groupedForm)
    .reduce((acc: any, curr: any) => {
      return {
        ...acc,
        ...curr
      };
    }, {});

  mergedObj = {
    ...mergedObj,
    ...(updatedKeyValue && {
      ...updatedKeyValue
    })
  };

  return mergedObj;
};

export const filterOutJobPageSteps = (steps: Step[]): Step[] => {
  return steps
    .filter(s => s.name !== 'date' && s.name !== 'payment');
};

export const filterOutJobPageStepsForValidation = (steps: Step[]): Step[] => {
  return steps
    .filter(s => s.name !== 'payment');
};

export const debounce = (func: Function, timeout: number = 300) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timer);

    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

// TODO: generalize
export const updatePinnables = () => {
  const isMobile: boolean = !isMinWidth(sizes.tablet);

  if (!isMobile) {
    return;
  }

  const pinnableElems = document.querySelectorAll('.pinnable');

  pinnableElems.forEach((pinnableElem) => {
    pinnableElem.classList.toggle('pinned', pinnableElem.getBoundingClientRect().top < 1);
  });
};

export const findLastStatusType = (statusList: Status[], statusType: StatusType): Status | null => {
  for (let i = statusList.length - 1; i >= 0; i--) {
    if (statusList[i].type === statusType) {
      return statusList[i];
    }
  }

  return null;
};

export const getExtractedNumericText = (str: string): string => {
  // strip dots and spaces
  const returnStr = str
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('+', '')
    .replaceAll(' ', '');

  return returnStr;
};

export const extractNumericInput = (e: any): void => {
  const val: string = (e.target.value as string);

  e.target.value = getExtractedNumericText(val);

  // check the full string (paste scenarios)
  // and remove earliest non numberic char to end
  if (isNaN(Number(val))) {
    const firstAlphaChar = val.search(/[^0-9]/);

    if (firstAlphaChar >= 0) {
      const validChars = val.substring(0, firstAlphaChar);

      e.target.value = validChars;
    }

    return;
  }
};

// Check the full string (paste scenarios)
// and remove earliest non numberic char to end
export const removeEarliestNonNumericChars = (str: string): string => {
  if (isNaN(Number(str))) {
    const firstAlphaChar = str.search(/[^0-9]/);

    if (firstAlphaChar >= 0) {
      const validChars = str.substring(0, firstAlphaChar);

      return validChars;
    }
  }

  return str;
};

export const getValidPhoneNumber = (str: string): string => {
  const sanitizedNumber: string = stripLeadingZeroFromNumber(
    stripCountryCodeFromNumber(
      getExtractedNumericText(str)
    )
  );

  // return sanitizedNumber;
  return `44${sanitizedNumber}`;
};

export const getCustomerName = (customer?: Customer): string => {
  if (!customer) {
    return 'No customer specified';
  }

  if (customer.firstName?.length
    && customer.lastName?.length
    && customer.businessName
  ) {
    return `${customer.firstName} ${customer.lastName} (${customer.businessName})`;
  }

  if (customer.firstName?.length && customer.lastName?.length) {
    return `${customer.firstName} ${customer.lastName}`;
  }

  if (customer.businessName?.length) {
    return customer.businessName;
  }

  if (customer.firstName?.length) {
    return customer.firstName;
  }

  if (customer.lastName?.length) {
    return customer.lastName;
  }

  if (customer.email?.length) {
    return customer.email;
  }

  return '';
};

export const accountingPlatformSelected = (client: Client): boolean => {
  return client.settings.accounting.platform !== AccountingPlatform.None;
};

export const qboUnAuthorized = (client: Client): boolean => {
  const isSelected: boolean = client.settings.accounting.platform === AccountingPlatform.QuickBooks;
  const isConnected: boolean = !!client.qboCompanyId;

  return isSelected && !isConnected;
};

export const qboAuthorizationUnfinished = (client: Client): boolean => {
  const isSelected: boolean = client.settings.accounting.platform === AccountingPlatform.QuickBooks;
  const isConnected: boolean = !!client.qboCompanyId;
  const isUnfinished: boolean = !isConnected && !!client.qboStateToken;

  return isSelected && isUnfinished;
};

export const qboAuthorizationConnectionError = (client: Client): boolean => {
  const isSelected: boolean = client.settings.accounting.platform === AccountingPlatform.QuickBooks;
  const connectionError: boolean = !!client.qboConnectionError;

  return isSelected && connectionError;
};

export const accountingPlatformSelectedAndNotAuthorized = (client: Client): [boolean, AccountingPlatform] => {
  switch (client.settings.accounting.platform) {
    case AccountingPlatform.QuickBooks:
      return [
        qboUnAuthorized(client)
          || qboAuthorizationUnfinished(client)
          || qboAuthorizationConnectionError(client),
        client.settings.accounting.platform
      ];
    default:
      return [
        false,
        client.settings.accounting.platform
      ];
  };
};

export const invoiceIdMissing = (
  client: Client,
  job: Job
): boolean => {
  switch (client.settings.accounting.platform) {
    case AccountingPlatform.QuickBooks:
      return !job.qboInvoiceId;
  };

  return false;
};

export const invoiceNotGenerated = (
  client: Client,
  job: Job
): boolean => {
  return accountingPlatformSelected(client)
    && invoiceIdMissing(client, job);
};

export const isCustomerInvoiceReady = (customer?: Customer): boolean => {
  if (!customer) {
    return false;
  }

  const hasPerson = (customer.firstName || '').length > 1 && (customer.lastName || '').length > 1;
  const hasBusiness = (customer.businessName || '').length > 1;
  const hasEmail = (customer.email || '').length > 1;

  const hasAddress = (customer.addressLine1 || '').length > 1 && (customer.addressCity || '').length > 1 && (customer.addressPostCode || '').length > 1;
  const hasBasicInfo = hasEmail && (hasPerson || hasBusiness);

  return hasBasicInfo && hasAddress;
};

export const isJobOverdue = (job: Job): boolean => {
  const jobDateLocal = new Date(stripZoneFromISOString(job.fields.date.iso[0]));

  return new Date() > jobDateLocal
    && !job.status.find(
      s => s.type === StatusType.PENDING
        || s.type === StatusType.CANCELLED
        || s.type === StatusType.COMPLETE
        || s.type === StatusType.DISPUTE
    );
};

export const stripZoneFromISOString = (isoString?: string): any => {
  if (!isoString) {
    return '';
  }

  return isoString.toString().replace('Z', '');
};

export const getJobToCopy = (job: Job): Partial<Job> => {
  const { date, ...fields } = job.fields;
  const referenceIncrement = (Number(job.reference.split('-')[1]) || 0) + 1;
  const reference = `${job.reference.split('-')[0]}-${referenceIncrement}`;

  const newJob = {
    reference,
    price: job.price,
    fields,
    customerId: job.customerId,
    customer: job.customer,
    noCustomerCommunications: job.noCustomerCommunications,
    steps: job.steps,
    serviceId: job.serviceId,
    vat: job.vat,
    fulfillerId: job.fulfillerId,
    fulfillerPay: job.fulfillerPay,
    fulfillerPayPercentage: job.fulfillerPayPercentage,
    fulfillerLabel: job.fulfillerLabel,
    staffNotes: job.staffNotes
  };

  return newJob;
};

export const getContrastingTextColor = (hex: string | undefined, defaultColor?: string): string => {
  if (!hex) {
    return defaultColor || '#000000';
  }

  hex = hex ? hex.replace('#', '') : '#fffff';
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // https://stackoverflow.com/a/3943023/112731
  const contrastThreshold: number = 149;
  const total: number = r * 0.299 + g * 0.587 + b * 0.114;

  return total > contrastThreshold ? '#000000' : '#FFFFFF';
}

export const extractFirstLastName = (customer?: string): { firstName?: string; lastName?: string } => {
  if (!customer) {
    return {};
  }

  const names: string[] = customer
    .trim()
    .split(' ')
    .filter(part => part);
  const hasName: boolean = names.length > 0;
  const firstName: string | null = hasName ? names[0] : null;
  const hasLastName: boolean = hasName && !!names[1] && names[1] !== firstName;
  const lastName: string | null = hasName && hasLastName ? names[1] : null;

  return {
    ...(firstName && {
      firstName: names[0]
    }),
    ...(lastName && {
      lastName: names[1]
    })
  };
};

export const getJobDuration = (job?: Job | null): string => {
  if (!job?.fields?.date?.iso?.length) {
    return '';
  }

  const workDays: number = Object.keys(job.fields.date.hours || {}).length;

  if (workDays) {
    return `${workDays} days`;
  }

  return formatDuration(intervalToDuration({
    start: new Date(stripZoneFromISOString(job.fields.date.iso[0])),
    end: new Date(stripZoneFromISOString(job.fields.date.iso[1]))
  }));
};

export const getBookingInvoiceNumber = (jobExpanded: Job): string => {
  let previousInvoices: number = 0;

  for (const status of jobExpanded.status) {
    const invoicesGenerated: number = (status.changes || []).filter(change => change.type === ChangeType.GB_INVOICE_GENERATED).length;

    if (invoicesGenerated) {
      previousInvoices += invoicesGenerated;
    }
  }

  previousInvoices++;

  return `GB${jobExpanded.clientId.slice(-4)}${jobExpanded._id.slice(-4)}${format(new Date(jobExpanded.created.at), 'yyMMdd')}${doubleSingleDigit(previousInvoices)}`.toUpperCase();
};

export const addCountryCodeToNumber = (phoneNumber: string): string => {
  // Assume GB for now

  return `44${phoneNumber}`;
};

export const stripCountryCodeFromNumber = (phoneNumber: string): string => {
  // Assume GB for now

  let strippedNumber = phoneNumber;

  if (phoneNumber?.charAt(0) === '4'
    && phoneNumber?.charAt(1) === '4'
  ) {
    strippedNumber = strippedNumber.slice(2);
  }

  return strippedNumber;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  // Assume GB country code for now

  let formattedNumber = (phoneNumber || '')
    .replace('44', '0')
    // Remove any spaces and plus sign
    .replace(/ |\+/g, '');
  
  formattedNumber = `${formattedNumber.slice(0, 4)} ${formattedNumber.slice(4, 7)} ${formattedNumber.slice(7, 11)}`;

  return formattedNumber;
};

export const stripLeadingZeroFromNumber = (phoneNumber: string): string => {
  let strippedNumber = phoneNumber;

  if (strippedNumber.charAt(0) === '0') {
    strippedNumber = strippedNumber.slice(1);
  }

  return strippedNumber;
};

export const getFulfillerPayPercentage = (fulfillerPay: number, price: number) => {
  return Number(Number(((fulfillerPay / 100) / price * 100) * 100).toFixed(2));
};

export const canCustomerMakePayment = (client?: Client | null): [boolean, PaymentOption[]] => {
  const paymentOptions: any[] = [];

  const clientAccountDetailsProvided: boolean = !!client?.settings.accounting?.businessAccountNumber
    && !!client?.settings.accounting?.businessSortCode;

  let customerPaymentMethodSetup: boolean = !!client?.customerPaymentService?.key;

  if (customerPaymentMethodSetup) {
    switch (client?.customerPaymentService?.type) {
      case CustomerPaymentServiceType.Stripe:
        customerPaymentMethodSetup = customerPaymentMethodSetup
          && !!client?.customerPaymentService.secret;

        if (customerPaymentMethodSetup) {
          paymentOptions.push(PaymentOption.CustomerPaymentMethod);
        }
        break;
    };
  }

  if (clientAccountDetailsProvided) {
    paymentOptions.push(PaymentOption.ACHTransfer);
  }

  const status: boolean = clientAccountDetailsProvided || customerPaymentMethodSetup;

  return [
    status,
    paymentOptions
  ];
};

